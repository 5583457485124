<template>
  <div class="liftList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(lifts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(lifts)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="cpStartFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cpStartFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cpStartFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="cpEndFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cpEndFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cpEndFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                slot="button"
                class="btn btn-danger btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span class="hide-down-sm">{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <LiftAdd
          v-if="addingElement"
          :resort-id="resortId"
          @reload="reloadLifts"
        />
        <LiftEdit
          v-else
          :lift="lift"
          @reload="reloadLifts"
        />
      </Sidebar>
      <SweetModal
        ref="confirmDelete"
        :title="$t('introOutroListComp.deleteElement')"
        icon="warning"
      >
        <p v-if="lift">
          {{ $t('sureToDelete', {'0' : lift.id}) }}
        </p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmDelete.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="removeLift()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />{{ $t('delete') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import LiftAdd from '@/components/Resort/LiftAdd.vue';
import LiftEdit from '@/components/Resort/LiftEdit.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "LiftList",
  components: {
    Sidebar,
    LiftAdd,
    LiftEdit,
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      lifts: null,
      lift: null,
      addingElement: false,
      showSidebar: false,
      deleteIndex: null,
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          title: 'Id',
          filterCell: "idFilter"
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter",
          hideOn: ['xsDown']
        },
        {
          field: 'cpStart.name',
          filterable: true,
          title: 'C-Point Start Name',
          filterCell: "cpStartFilter",
          hideOn: ['smDown']
        },
        {
          field: 'cpEnd.name',
          filterable: true,
          title: 'C-Point End Name',
          filterCell: "cpEndFilter",
          hideOn: ['smDown']
        },
        {
          field: 'type.name',
          filterable: true,
          title: 'Type',
          hideOn: ['smDown'],
          filterCell: "typeFilter"
        },
        {
          field: 'duration',
          filterable: false,
          title: 'Durtation',
          hideOn: ['smDown']
        },
        {
          field: 'length',
          filterable: false,
          title: 'Length',
          hideOn: ['smDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  watch: {
    resortId () {
      this.getData();
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //sidebar
     openConfirmModal (data) {
       this.setCurrentLift(data);
      this.deleteIndex = data.sid;
      this.$refs.confirmDelete.open();
    },
    closeModal () {
      this.reloadLifts();
    },
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentLift(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentLift (lift) {
      this.lift = JSON.parse(JSON.stringify(lift));
    },
    reloadLifts () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getData();
    },
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.get(`/Resort/GetResortLifts?resortId=${ this.resortId }`)
      .then(response => {
        this.lifts = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeLift () {
      this.axios.delete(`/Resort/RemoveLift?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('resortForm.removedSuccessfullyLift'));
        this.deleteIndex = null;
        this.$refs.confirmDelete.close();
        this.reloadLifts();
      })
    }
  }
}
</script>
<style>
.liftList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>