<template>
  <div class="liftAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ 'C-Point Start' }}
        </h4>
        <hr class="m-0 mb-3">
        <div class="ml-3">
          <label>{{ $t('type') }}</label>
          <select
            v-model="cPointStart.type"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Type') }]"
          >
            <option
              v-for="(sType, index) in cPointTypes"
              :key="`spotType-spot-${ index }`"
              :value="sType"
            >
              {{ `${ sType }` }}
            </option>
          </select>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="cPointStart.name"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('altitude') }}</label>
          <input
            v-model="cPointStart.altitude"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Altitude') }]"
            type="text"
          >
        </div>
        <h4 class="m-0 mt-3">
          {{ 'C-Point End' }}
        </h4>
        <hr class="m-0 mb-3">
        <div class="ml-3">
          <label>{{ $t('type') }}</label>
          <select
            v-model="cPointEnd.type"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Type') }]"
          >
            <option
              v-for="(eType, index) in cPointTypes"
              :key="`spotType-spot-${ index }`"
              :value="eType"
            >
              {{ `${ eType }` }}
            </option>
          </select>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="cPointEnd.name"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('altitude') }}</label>
          <input
            v-model="cPointEnd.altitude"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Altitude') }]"
            type="text"
          >
        </div>
        <h4 class="m-0 mt-3">
          {{ 'Section' }}
        </h4>
        <hr class="m-0 mb-3">
        <div class="ml-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="section.name"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('type') }}</label>
          <select
            v-model="section.type"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Type') }]"
          >
            <option
              v-for="(type, index) in sectionTypes"
              :key="`spotType-spot-${ index }`"
              :value="type"
            >
              {{ `${ type.id } - ${ type.name }` }}
            </option>
          </select>
          <hr class="m-0 mb-3">
          <label>{{ $t('length') }}</label>
          <input
            v-model="section.length"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Length') }]"
            type="text"
          >
          <label>{{ $t('resortForm.multipleValue') }}</label>
          <hr class="m-0 mb-3">
          <label>{{ $t('duration') }}</label>
          <input
            v-model="section.duration"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Duration') }]"
            type="text"
          >
          <label>{{ $t('resortForm.multipleValue') }}</label>
          <hr class="m-0 mb-3">
          <template
            v-if="hasAxess != null && hasAxess == false"
          >
            <label>{{ 'GGNR' }}</label>
            <input
              v-model="ggnr"
              :class="['form-control', { 'is-invalid': $validator.errors.has('Ggnr') }]"
              type="text"
            >
          </template>
          <template
            v-else-if="hasAxess != null && hasAxess"
          >
            <label>{{ $t('resortForm.uniqueNameLift') }}</label>
            <input
              v-model="uniqueName"
              :class="['form-control', { 'is-invalid': $validator.errors.has('UniqueName') }]"
              type="text"
            >
          </template>
        </div>
      </div>
      <button
        class="btn btn-primary float-right"
        @click.prevent="addLift()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "LiftAdd",
  mixins: [
    errorMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      cPointStart: {
        type: 'Talstation',
        name: null,
        altitude: null
      },
      cPointEnd: {
        type: 'Bergstation',
        name: null,
        altitude: null
      },
      section: {
        name: null,
        type: null,
        duration: null,
        length: null
      },
      ggnr: null,
      sectionTypes: null,
      hasAxess: null,
      uniqueName: null
    }
  },
  computed: {
    cPointTypes () {
      return ['Talstation', 'Bergstation'];
    }
  },
  watch: {
    'cPointStart.type': function (val) {
      if(val == this.cPointTypes[0]) {
        this.cPointEnd.type = this.cPointTypes[1];
      }
      else {
        this.cPointEnd.type = this.cPointTypes[0];
      }
    },
    'cPointEnd.type': function (val) {
      if(val == this.cPointTypes[0]) {
        this.cPointStart.type = this.cPointTypes[1];
      }
      else {        
        this.cPointStart.type = this.cPointTypes[0];
      }
    },
    'cPointStart.name': function (val) {
      if(this.cPointStart.type == this.cPointTypes[0] && val && val.length > 0 && val.toLowerCase().includes(this.cPointTypes[0].toLowerCase())) {
        this.section.name = val.replace(this.cPointTypes[0], "").trim();
      }
      else if(this.cPointStart.type == this.cPointTypes[1] && val && val.length > 0 && val.toLowerCase().includes(this.cPointTypes[1].toLowerCase())) {
        this.section.name = this.sectionName(val, this.cPointEnd.name);
      }
    },
    'cPointEnd.name': function (val) {
      if(this.cPointEnd.type == this.cPointTypes[0] && val && val.length > 0 && val.toLowerCase().includes(this.cPointTypes[0].toLowerCase())) {
        this.section.name = this.sectionName(this.cPointStart.name, val);
      }
    }
  },
  created () {
    this.getSectionTypes();
  },
  methods: {
    sectionName (cOne, cTwo) {
      return cOne + ' > ' + cTwo;
    },
    async addLift () {
      var lift = {
        cPointStart: this.cPointStart,
        cPointEnd: this.cPointEnd,
        section: this.section,
        resortId: this.resortId,
        ggnr: this.ggnr,
        uniqueName: this.uniqueName
      }
      await this.axios.post('/Resort/CreateLift', lift)
        .then(() => {
          this.$snotify.success(this.$t('resortForm.addedSuccessfullyLift'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getSectionTypes () { 
      this.axios.get(`/Resort/GetResortSectionTypes`)
      .then(response => {
        this.sectionTypes = response.data;
        this.section.type = this.sectionTypes[0];
      })
      .finally(() => {
        this.checkIfAxess();
        this.loading = false;
      });
    },
    checkIfAxess () { 
      this.axios.get(`/ConverterConfig/CheckIfAxessConverterConfig?resortId=` + this.resortId)
      .then(response => {
        this.hasAxess = response.data;
      });
    }
  }
}
</script>

<style scoped>
.liftAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>